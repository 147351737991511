import { useEffect, useState } from 'react';
import { Container, Fade, Image } from 'react-bootstrap'
import Divider from './Divider';

export default function LandingScreen() {
  const bannerImageLink = "https://ousud.files.wordpress.com/2021/12/ousud-basic-banner-optimized.png";
  const logoWithMotoLink = "https://ousud.files.wordpress.com/2021/11/logo_and_moto_nobg-01.png";

  const [showingText, setShowingText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowingText(true)
    }, 500);
  })

  return (
    <div id='ousud-jumbotron'>
      <Image src={bannerImageLink} id="ousud-jumbotron-image" />
      <Container fluid>
        <div id="ousud-jumbotron-text">
          <Image src={logoWithMotoLink} id="ousud-jumbotron-logo-img" />
          <Fade in={showingText} >
            <h1>We bring the pharmacy to you</h1>
          </Fade>
        </div>
      </Container>
      <Container id="sub-banner-1">
        <Fade in={showingText} >
          <h1 className="color-1">Authentic medicines delivered to your doorstep, <br /> anywhere in Dhaka City.</h1>
        </Fade>
        <Divider />
      </Container>
      <Container id="sub-banner-2" fluid>
        <div id="contact-us-btn">
          <a href="tel:+8801873787878">
            Call 01873787878 to Order
          </a>
        </div>
        <h3>
          Or message us on <a href="https://fb.com/ousud" target="_blank" rel="noreferrer" className="no-hover-link color-1">Facebook</a> | <a href="https://wa.me/+8801873787878" target="_blank" rel="noreferrer" className="no-hover-link color-1">WhatsApp</a> | <a href="viber://chat?number=+8801873787878" target="_blank" rel="noreferrer" className="no-hover-link color-1">Viber</a>
        </h3>
        <h5 className='margin-top-1'>Our secondary hotline number is <a href="tel:+8801744123123">+8801744123123</a></h5>
      </Container>
    </div>
  )
}
