import MessengerCustomerChat from 'react-messenger-customer-chat';
import AboutUsCards from './components/AboutUsCards';
import Footer, { CopyrightFooter } from './components/Footer';
import InfoCards from './components/InfoCards';
import LandingScreen from './components/LandingScreen';
import ContactForm from './components/ContactForm';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';

function App() {

  return (
    <>
      <div className="App">
        <LandingScreen />

        <AboutUsCards />

        <Container>
          <Row className='margin-top-2'>
            <Col xs={12} md={6}>
              <InfoCards />
            </Col>
            <Col xs={12} md={6}>
              <ContactForm />
            </Col>
          </Row>
        </Container>

        <Footer />

        <CopyrightFooter />
      </div>
      <MessengerCustomerChat
        pageId="461123684022359"
        appId="518304478343147"
      />
    </>
  );
}

export default App;
