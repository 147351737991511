import { Container, Row, Col, Card } from 'react-bootstrap'

export default function AboutUsCards() {
  return (
    <Container id="about-us-cards">
      <Row>
        <Col md={12} lg={4}>
          <h2 className="text-center color-1 margin-top-1 margin-bottom-sm">100% Authentic</h2>
          <Card.Img variant="top" src="https://ousud.files.wordpress.com/2021/12/directly-from-pharma-optz.png" />
          <p className="about-us-card-description">
            We source our medicines directly from pharmaceutical companies, to give you the best quality products in the market.
          </p>
          <hr className="margin-top-2" />
        </Col>
        <Col md={12} lg={4}>
          <h2 className="text-center color-1 margin-top-1 margin-bottom-sm">Physical Pharmacy</h2>
          <Card.Img variant="top" src="https://ousud.files.wordpress.com/2021/12/ousud-ankur-optimized-1.png" />
          <p className="about-us-card-description">
            We deliver directly from our store in Dhanmondi. You can also get the same great customer experience in-store!
          </p>
          <hr className="margin-top-2" />
        </Col>
        <Col md={12} lg={4}>
          <h2 className="text-center color-1 margin-top-1 margin-bottom-sm">We Care About You</h2>
          <Card.Img variant="top" src="https://ousud.files.wordpress.com/2021/12/customer-first-optz.png" />
          <p className="about-us-card-description">
            We keep track of what you need, and give you reminders so you always have the medicines when you need them.
          </p>
          <hr className="margin-top-2" />
        </Col>
      </Row>
    </Container>
  )
}