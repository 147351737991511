import { Col, Container, Row } from 'react-bootstrap';

export default function Footer() {
  return (
    <Container fluid id="footer">
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <h2 className="color-1 margin-bottom-sm font-bold">About Us</h2>
            <p className="about-us-card-description">
              Ousud.com delivers medicine to your doorstep, sourced directly from pharmaceutical companies, and sold from our own physical store.
              <br />
              We bring the pharmacy to you!
              <br />
              <br />
              <small>(*Delivery only within Dhaka City)</small>
            </p>
          </Col>
          <Col md={12} lg={3}>
            <h2 className="color-1 margin-bottom-sm font-bold">Contact Us</h2>
            <p className="about-us-card-description">
              Phone / WhatsApp Hotline: <br /><b className="color-2"><a href="tel:+8801873787878" className="no-hover-link color-1">+8801873787878</a></b>
            </p>
            <p>
              Secondary Hotline: <a href="tel:+8801744123123" className="no-hover-link color-1">+8801744123123</a>
            </p>
            <p className="about-us-card-description">
              Facebook Page: <br /><a href="https://fb.com/ousud" className="color-2 no-hover-link" target="_blank" rel="noreferrer">facebook.com/ousud</a>
            </p>
          </Col>
          <Col md={12} lg={3}>
            <h2 className="color-1 margin-bottom-sm font-bold">Visit Us</h2>
            <p className="about-us-card-description">
              Dhanmondi 9/A, <br /> Dhaka, Bangladesh <br />
              <small>(Opposite of Ibne Sina Hospital)</small>
            </p>
            <p className="about-us-card-description">
              <a href="https://www.google.com/maps/@23.7448145,90.3714453,21z" className="color-2 no-hover-link" target="_blank" rel="noreferrer">Google Maps Link</a>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export function CopyrightFooter() {
  return (
    <div id="copyright">
      © Ousud.com | All Rights Reserved
    </div>
  )
}
