import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

function getRandomInputs() {
  const first = Math.floor(Math.random() * 10) + 1;
  const second = Math.floor(Math.random() * 10) + 1;

  return {
    first,
    second,
    sum: first + second
  }
}

export default function ContactForm() {
  const [showSubmit, setShowSubmit] = useState(false);
  const [phone, setPhone] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState();

  const [showPhoneError, setShowPhoneError] = useState(false);

  const validatePhone = () => phone.match(/^(\+?88)?(01)[3456789]{1}\d{8}$/gi);

  const [noBotCheck, setNoBotCheck] = useState({});

  useEffect(() => {
    const botprevent = getRandomInputs();
    setNoBotCheck(botprevent)
  }, [])

  useEffect(() => {
    if (Number(verifyNumber) === noBotCheck.sum) {
      setShowSubmit(true)
    } else {
      setShowSubmit(false)
    }
  }, [verifyNumber, noBotCheck])

  const verifyInput = (e) => {
    if (!validatePhone(phone)) {
      setShowPhoneError(true);
      e.preventDefault();
    } else {
      setShowPhoneError(false);
    }
  }

  return (
    <div className="alert-box">
      <h3 className="color-1">Contact Us</h3>
      <h6>Upload your prescription or write down your medicine list, <br /> and we will contact you for delivery</h6>
      <br />

      <form
        id="contactForm"
        action="https://submit-form.com/7w3gjx2G"
      >
        <div className="form-group">
          <input placeholder="Your name" name="contactName" required={true} type="text" id="contactName" className="form-control" />
        </div>
        <div className="form-group">
          <input
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Your Phone" name="contactPhone" required={true} type="text" id="contactPhone" className="form-control" />
          <small hidden={!showPhoneError} style={{ color: 'red', fontWeight: 'bold' }}>phone number is invalid!</small>
        </div>
        <div className="form-group">
          <textarea placeholder="Order details, medicine list, etc." name="contactOrderDetails" id="contactOrderDetails" className="form-control"></textarea>
        </div>

        <div className="form-group">
          <label>{noBotCheck.first} + {noBotCheck.second} = &nbsp; </label>
          <input onChange={(e) => setVerifyNumber(e.target.value)} value={verifyNumber} required={true} style={{ width: '60px', textAlign: 'center' }} />
        </div>
        <Row>
          <Col xs={12} md={6}>
            {/* eslint-disable-next-line */}
            <input type="hidden" role="uploadcare-uploader" name="prescription" />
          </Col>
          <Col xs={12} md={6}>
            <button
              onClick={(e) => verifyInput(e)}
              type="submit"
              hidden={!showSubmit}
              className="btn btn-primary btn-block">
              Submit
            </button>
          </Col>
        </Row>


      </form>
    </div>
  )
}