export default function InfoCards() {
  return (
    <>
      <div className='alert-box color-1 margin-bottom-1'>
        <h3>Delivery charge 75৳ across Dhaka City</h3>
        <h5>(Dhanmondi area 40৳)</h5>
      </div>
      <div className='alert-box color-3 margin-bottom-1'>
        <h3>Open Hours: 11 AM to 9 PM </h3>
        <h5>Every Day.</h5>
      </div>
      <div className='alert-box color-2 margin-bottom-1'>
        <h5>For prescription medicines, we will request a copy of your prescription or doctor's note.</h5>
        <h6>You can send us a copy on Facebook or WhatsApp.</h6>
      </div>
    </>
  )
}